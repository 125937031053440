import useFormHelpers from "./useFormHelpers";
import useStore from "./useStore";

export default function useValidateForm() {
    const { required, emailValid, dateValid, birthdateValid, hasError } = useFormHelpers();
    const formState = useStore((state) => state.formState);
    const requiredFormFields = useStore((state) => state.requiredFormFields);
    const emailFormFields = useStore((state) => state.emailFormFields);
    const dateFormFields = useStore((state) => state.dateFormFields);
    const birthdateFormFields = useStore((state) => state.birthdateFormFields);
    const setFormErrors = useStore((state) => state.setFormErrors);

    return () => {
        const errors = {
            ...required(formState, requiredFormFields),
            ...emailValid(formState, emailFormFields),
            ...dateValid(formState, dateFormFields),
            ...birthdateValid(formState, birthdateFormFields),
        };
        setFormErrors(errors);
        return { isValid: !hasError(errors), errors };
    };
}
