import { styled } from "@mui/material";
import { Outlet } from "react-router-dom";
import ErrorSnackbar from "./ErrorSnackbar";
import InfoSnackbar from "./InfoSnackbar";
import { formatInTimeZone } from "date-fns-tz";

export default function Layout() {
    return (
        <>
            <OuterContainer>
            <InnerContainer>
                <Banner src="images/Banner_v2.jpg" alt="banner" />
                <Outlet />
                <Spacer />
                <Footer>© {formatInTimeZone(new Date(), "Europe/Berlin", "yyyy")} UMCH - Universitätsmedizin Neumarkt a. M. Campus Hamburg</Footer>
            </InnerContainer>
            </OuterContainer>
            <ErrorSnackbar />
            <InfoSnackbar />
        </>
    );
}

const OuterContainer = styled("div")({
    backgroundColor: "rgb(250,250,250)"
});

const InnerContainer = styled("div")({
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    maxWidth: 1024,
    margin: "0 auto",
    backgroundColor: "white"
});

const Spacer = styled("div")({
    flexGrow: 1
});

const Banner = styled("img")({
    width: "100%",
    objectFit: "cover",
    maxHeight: "calc(100vh * 0.4)",
});

const Footer = styled("div")({
    width: "calc(100% - 64px)",
    paddingLeft: 32,
    paddingRight: 32,
    height: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    backgroundColor: "#868686",
});
