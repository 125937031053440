import { PlayCircleFilledOutlined } from "@mui/icons-material";
import { Box, Button, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import StoreCheckbox from "components/StoreCheckbox";
import Section from "components/Section";
import Subsection from "components/Subsection";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player/youtube"; // https://www.npmjs.com/package/react-player
import useStore from "hooks/useStore";

export default function SafetyInstruction() {
    const errors = useStore((state) => state.formErrors);
    const isDesktop = useMediaQuery("(min-width:1240px)");
    const [isSafetyInstructionsVisible, setIsSafetyInstructionsVisible] = useState(true);
    const [videoState, setVideoState] = useState("not_started");

    const toggleSafetyInstructions = () => {
        setIsSafetyInstructionsVisible(!isSafetyInstructionsVisible);
    };

    useEffect(() => {
        if (!!errors.safetyInstructionsAccept) {
            setIsSafetyInstructionsVisible(true);
        }
    }, [errors]);

    return (
        <Section title="Safety Instruction" number={3} errorMessage="Please click the checkbox.">
            <Subsection>
                <Typography>Your safety is important to us.</Typography>
                <Typography>
                    Please inform yourself about the safety instructions on campus by watching the safety video in full length. Afterwards, please confirm that
                    you have been taught the safety instructions.
                </Typography>
            </Subsection>
            <Subsection>
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=3IvpQ5OYB3g"
                    width={isDesktop ? 784 : "calc(100vw - 60px)"}
                    height={isDesktop ? (784 * 9) / 16 : "calc((100vw - 60px) * 9 / 16)"}
                    controls
                    onStart={() => setVideoState("started")}
                />
            </Subsection>
            <Subsection>
                <Button onClick={toggleSafetyInstructions} variant="outlined">
                    <PlayCircleFilledOutlined color="primary" style={isSafetyInstructionsVisible ? { transform: "rotate(90deg)" } : {}} />
                    <Typography fontWeight="bold" variant="button">
                        &nbsp;Safety Instructions
                    </Typography>
                </Button>
                <Box mt={2} mb={2} padding={2} sx={{ border: "1px solid gray" }} style={{ display: isSafetyInstructionsVisible ? "block" : "none" }}>
                    <SafetyInstructionDocument videoState={videoState} />
                </Box>
            </Subsection>
        </Section>
    );
}

function SafetyInstructionDocument({ videoState }) {
    const theme = useTheme();
    const setPartialFormState = useStore((state) => state.setPartialFormState);
    const setInfo = useStore((state) => state.setInfo);
    const [alreadyDisplayed, setAlreadyDisplayed] = useState(false);

    const onChange = (event) => {
        const safetyInstructionsAccept = event.target.checked;
        setPartialFormState({ safetyInstructionsAccept });
        if (!alreadyDisplayed && safetyInstructionsAccept && videoState === "not_started") {
            setAlreadyDisplayed(true);
            setInfo("Please make sure to watch the video");
        }
    };

    return (
        <StyledSafetyInstructionDocument>
            <Typography variant="h2" sx={{ color: theme.palette.text.primary, textAlign: "center" }} mb={4}>
                Safety Instructions
            </Typography>
            <Typography paragraph>
                I hereby confirm that I have participated in a safety briefing of CPE Europe GmbH and that I have been instructed and trained on the following
                topics in accordance with the Arbeitsschutzgesetz § 12 bzw. DGUV Vorschrift 1 § 4:
            </Typography>
            <BlueBox sx={{ marginBottom: 2 }}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Typography fontWeight="bold">General information:</Typography>
                        <StyledUl>
                            <li>
                                <Typography>basics</Typography>
                            </li>
                            <li>
                                <Typography>knifes</Typography>
                            </li>
                            <li>
                                <Typography>electrical equipment</Typography>
                            </li>
                            <li>
                                <Typography>skin protection</Typography>
                            </li>
                            <li>
                                <Typography>safety walking</Typography>
                            </li>
                            <li>
                                <Typography fontWeight="bold">Workstations</Typography>
                            </li>
                            <li>
                                <Typography fontWeight="bold">Biochemicals</Typography>
                            </li>
                        </StyledUl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography fontWeight="bold">Hazardous substances:</Typography>
                        <StyledUl>
                            <li>
                                <Typography>basics</Typography>
                            </li>
                            <li>
                                <Typography>toxic materials</Typography>
                            </li>
                            <li>
                                <Typography>CMR-substances</Typography>
                            </li>
                            <li>
                                <Typography>gases</Typography>
                            </li>
                            <li>
                                <Typography fontWeight="bold">Behavior in case of fire</Typography>
                            </li>
                            <li>
                                <Typography fontWeight="bold">Accidents at work</Typography>
                            </li>
                            <li>
                                <Typography fontWeight="bold">First aid</Typography>
                            </li>
                        </StyledUl>
                    </Grid>
                </Grid>
            </BlueBox>
            <StoreCheckbox
                name="safetyInstructionsAccept"
                label={`I have watched the safety-instruction video until the end, and I have been given the opportunity to ask questions about the content. 
                    All my open questions in this regard were answered. I was familiarized with the safety instructions documentations, and I know where I can find them. `}
                required
                color="primary"
                onChange={onChange}
            />
        </StyledSafetyInstructionDocument>
    );
}

const StyledSafetyInstructionDocument = styled("div")(({ theme }) => ({
    fontSize: theme.typography.body1.fontSize,
}));

const BlueBox = styled("div")({
    backgroundColor: "#A1B8E1",
    border: "1px solid black",
    padding: 16,
});

const StyledUl = styled("ul")({
    listStyleType: '"- "',
});
