import { Box, Card, CardActions, CardContent, CardHeader, IconButton, Slide, styled, TextField } from "@mui/material";
import useStore from "hooks/useStore";
import useApiFetch from "hooks/useApiFetch";
import { useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import NextIcon from "@mui/icons-material/ArrowForward";
import BackIcon from "@mui/icons-material/ArrowBack";
import EmailIcon from "@mui/icons-material/MarkEmailRead";
import ContactInfo from "components/ContactInfo";
import { isEmail } from "hooks/useFormHelpers";

export default function Login() {
    const apiFetch = useApiFetch();
    const setError = useStore((state) => state.setError);
    const [loadingSendLoginLink, setLoadingSendLoginLink] = useState(false);
    const [email, setEmail] = useState("");
    const [page, setPage] = useState(0);
    const [isNextButtonVisible, setIsNextButtonVisible] = useState(false);
    const containerRef = useRef(null);
    const [wrongEmail, setWrongEmail] = useState(false);

    const loginLink = async () => {
        if (!isEmail(email)) {
            setWrongEmail(true);
            return;
        }
        setLoadingSendLoginLink(true);
        const data = { email: email?.trim() };
        try {
            const { status, statusText } = await apiFetch("loginLink", data);
            if (status === 200) {
                setPage(1);
                setIsNextButtonVisible(true);
            } else {
                setError(statusText);
                if (status === 401) {
                    setWrongEmail(true);
                    apiFetch("error", { message: "[Warning] Failed to create login-link, user not found in Salesforce", method: "loginLink", data }).catch(() => {});
                }
            }
        } catch (error) {
            setError(error);
            apiFetch("error", { message: error?.message, method: "loginLink", data }).catch(() => {});
        }
        setLoadingSendLoginLink(false);
    };

    const onKeyDown = (event) => {
        if (event.key === "Enter") {
            loginLink();
        }
    };

    const next = () => setPage(1);
    const back = () => setPage(0);

    const onChange = (event) => {
        setEmail(event.target.value);
        setWrongEmail(false);
    }

    return (
        <Container ref={containerRef}>
            {page === 0 && (
                <Slide direction="right" timeout={0} in mountOnEnter container={containerRef.current}>
                    <Card raised>
                        {isNextButtonVisible && (
                            <FlexEnd>
                                <IconButton aria-label="next" onClick={next}>
                                    <NextIcon />
                                </IconButton>
                            </FlexEnd>
                        )}
                        <CardHeader
                            titleTypographyProps={{ align: "center" }}
                            subheaderTypographyProps={{ align: "center" }}
                            title="Login"
                            subheader="Enter your email address and we will send you a link to log into the Digital Document Station."
                        />
                        <CardContent>
                            <TextField
                                fullWidth
                                margin="none"
                                id="email"
                                label="Email"
                                type="email"
                                variant="outlined"
                                onChange={onChange}
                                value={email}
                                onKeyDown={onKeyDown}
                                error={wrongEmail}
                                helperText={wrongEmail ? "Please enter your correct email address" : ""}
                            />
                            {wrongEmail && (
                                <ContactInfo mt={2}/>
                            )}
                        </CardContent>
                        <CardActions>
                            <LoadingButton loading={loadingSendLoginLink} fullWidth onClick={loginLink} variant="contained" disabled={!email}>
                                Send me a login link
                            </LoadingButton>
                        </CardActions>
                    </Card>
                </Slide>
            )}
            {page === 1 && (
                <Slide direction="left" timeout={250} in mountOnEnter container={containerRef.current}>
                    <Card raised>
                        <IconButton aria-label="back" onClick={back}>
                            <BackIcon />
                        </IconButton>
                        <CardHeader
                            titleTypographyProps={{ align: "center" }}
                            subheaderTypographyProps={{ align: "center" }}
                            title="Check your emails!"
                            subheader={`We sent a login link to ${email}`}
                        />
                        <CardContent>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <EmailIcon color="primary" fontSize="large" />
                            </Box>
                        </CardContent>
                    </Card>
                </Slide>
            )}
        </Container>
    );
}

const Container = styled("div")({
    maxWidth: 500,
    margin: "30px auto",
});

const FlexEnd = styled("div")({
    display: "flex",
    justifyContent: "flex-end",
});
