import create from "zustand";
import { immer } from "zustand/middleware/immer";

// https://www.npmjs.com/package/zustand

const log = (config) => (set, get, api) =>
    config(
        (...args) => {
            set(...args);
            // console.log("New state", get());
        },
        get,
        api
    );

export default create(
    log(
        immer((set) => ({
            error: null,
            setError: (error) =>
                set((state) => {
                    state.error = error;
                }),
            info: null,
            setInfo: (info) =>
                set((state) => {
                    state.info = info;
                }),
            currentSection: 1,
            setCurrentSection: (section) =>
                set((state) => {
                    state.currentSection = section;
                }),
            student: null,
            setStudent: (student) =>
                set((state) => {
                    state.student = student;
                }),
            formState: {},
            setPartialFormState: (partialFormState) =>
                set((state) => {
                    for (let key of Object.keys(partialFormState)) {
                        state.formState[key] = partialFormState[key];
                        state.formErrors[key] = false;
                    }
                }),
            formErrors: {},
            setFormErrors: (formErrors) =>
                set((state) => {
                    state.formErrors = formErrors;
                }),
            requiredFormFields: {},
            setRequiredFormField: (name, required) =>
                set((state) => {
                    state.requiredFormFields[name] = required;
                }),
            emailFormFields: {},
            setEmailFormField: (name, isEmail) =>
                set((state) => {
                    state.emailFormFields[name] = isEmail;
                }),
            dateFormFields: {},
            setDateFormField: (name, isDate) =>
                set((state) => {
                    state.dateFormFields[name] = isDate;
                }),
            birthdateFormFields: {},
            setBirthDateFormField: (name, isDate) =>
                set((state) => {
                    state.birthdateFormFields[name] = isDate;
                }),
            clear: () => set({ student: null, formState: {}, formErrors: {} }),
        }))
    )
);
