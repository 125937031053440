import { ToggleButton, Typography, useTheme } from "@mui/material";
import useStore from "hooks/useStore";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";

export default function StoreToggleButton({ label, children, onChange, name, value, required, sx, span, ...props }) {
    const theme = useTheme();
    const setPartialFormState = useStore((state) => state.setPartialFormState);
    const errors = useStore((state) => state.formErrors);
    const formState = useStore((state) => state.formState);
    const setRequiredFormField = useStore((state) => state.setRequiredFormField);
    const student = useStore((state) => state.student);

    useEffect(() => {
        setRequiredFormField(name, required);
    }, [required, name, setRequiredFormField]);

    const _onChange = (event, value) => {
        if (onChange) {
            onChange(event, value);
        } else {
            setPartialFormState({ [name]: formState[name] === value ? null : value });
        }
    };

    return student ? (
        <ToggleButton
            {...props}
            name={name}
            onChange={_onChange}
            selected={value === formState[name]}
            value={value || ""}
            color="secondary"
            sx={{
                ...sx,
                gridColumn: `auto / span ${span}`,
                color: errors[name] ? theme.palette.error.main : theme.palette.primary.main,
                padding: "8px 22px",
            }}
            style={{
                borderColor: errors[name] ? theme.palette.error.main : theme.palette.primary.main,
            }}
        >
            {label && (
                <Typography variant="button" textAlign="center">
                    {label}&nbsp;{required ? "*" : ""}
                </Typography>
            )}
            {children}
        </ToggleButton>
    ) : (
        <Skeleton height={46} width={props?.width} />
    );
}

StoreToggleButton.defaultProps = {
    span: 1,
    sx: {},
    label: "",
};
