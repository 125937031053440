import { PlayCircleFilledOutlined } from "@mui/icons-material";
import { Box, Button, styled, Typography, useTheme } from "@mui/material";
import StoreCheckbox from "components/StoreCheckbox";
import Section from "components/Section";
import Subsection from "components/Subsection";
import { useEffect, useState } from "react";
import useStore from "hooks/useStore";
import ExternalLink from "components/ExternalLink";

export default function Documents() {
    const errors = useStore((state) => state.formErrors);
    const [isDeclarationOfConsentVisible, setIsDeclarationOfConsentVisible] = useState(true);

    const toggleIsDeclarationOfConsentVisible = () => {
        setIsDeclarationOfConsentVisible(!isDeclarationOfConsentVisible);
    };

    useEffect(() => {
        if (errors.declarationOfConsentAccept_1 || errors.declarationOfConsentAccept_2 || errors.termsAndConditionsAccept) {
            setIsDeclarationOfConsentVisible(true);
        }
    }, [errors]);

    return (
        <Section title="Documents" number={4} errorMessage="The documents contain 2 required checkboxes. Please click both.">
            <Subsection>
                <Typography>Please read the following documents carefully and confirm that you have done so. Thank you very much.</Typography>
            </Subsection>
            <Subsection>
                <Button onClick={toggleIsDeclarationOfConsentVisible} variant="outlined">
                    <PlayCircleFilledOutlined color="primary" style={isDeclarationOfConsentVisible ? { transform: "rotate(90deg)" } : {}} />
                    <Typography fontWeight="bold" variant="button">
                        &nbsp;Declaration of Consent
                    </Typography>
                </Button>
                <Box mt={2} mb={2} padding={2} sx={{ border: "1px solid gray" }} style={{ display: isDeclarationOfConsentVisible ? "block" : "none" }}>
                    <DeclarationOfConsent />
                </Box>
            </Subsection>
        </Section>
    );
}

function DeclarationOfConsent() {
    const theme = useTheme();

    return (
        <StyledDeclarationOfConsent>
            <Typography variant="h2" sx={{ color: theme.palette.text.primary, textAlign: "center" }} mb={4}>
                Declaration of Consent
            </Typography>
            <Typography paragraph>
                The above-designated person (hereinafter “student”) declares his/her perusal, acknowledgement, and consent that the regulations, house rules,
                legal directions, and transfers of rights which are dealt with in the following, amend
            </Typography>
            <StyledOl>
                <li>
                    <Typography>
                        the Study Contract between the student and George Emil Palade University of Medicine, Pharmacy, Science, and Technology of Targu Mures
                        (hereinafter “UMFST”) and
                    </Typography>
                </li>
                <li>
                    <Typography>the Service Contract between the student and CPE Europe GmbH (hereinafter “campus management”).</Typography>
                </li>
            </StyledOl>
            <Typography fontWeight="bold">I. General Regulations</Typography>
            <StyledOl>
                <li>
                    <Typography paragraph>
                        The student – as a participant of the human medicine study program at the UMFST’s Hamburg branch, the University Targu Mures Medical
                        Campus Hamburg (hereinafter “UMFST-UMCH”) – confirms the acknowledgment and perusal of
                    </Typography>
                    <StyledOl type="a">
                        <li>
                            <Typography paragraph>the house rules of UMFST-UMCH,</Typography>
                        </li>
                        <li>
                            <Typography paragraph>
                                the security and safety regulations of UMFST-UMCH; moreover, the student agrees to attend the necessary safety briefings offered
                                either by UMFST-UMCH or the campus management (either digitally or in person), and
                            </Typography>
                        </li>
                    </StyledOl>
                </li>
                <li>
                    <Typography paragraph>
                        The student commits himself/herself to pursue all regulations and rules which are mentioned in paragraph 1.
                    </Typography>
                    <Typography paragraph>
                        The student proves to the campus management – at the latest by the time of the commencing of his/her study program at UMFST-UMCH – that
                        he/she maintains a health insurance, accident insurance, and personal liability insurance valid throughout the EU with sufficient
                        coverage (according to point C.II. of the CPE Service Contract – “Health, Accident and Liability Insurance”). In addition, for bedside
                        teaching in teaching hospitals, at the start of the third academic year of study at the latest, the applicant/student proves to CPE
                        without being requested to do so that they hold professional liability insurance valid throughout the EU, covering medical activities
                        carried out under the supervision and/or instruction of a duly qualified doctor.
                    </Typography>
                </li>
            </StyledOl>
            <Typography fontWeight="bold">II. Conduct on the (virtual) Campus</Typography>
            <StyledOl>
                <li>
                    <Typography paragraph>
                        The student pledges himself/herself to behave courteously and respectfully towards all his/her fellow students, the instructors, and the
                        representatives of UMFST-UMCH/the campus management.
                    </Typography>
                </li>
                <li>
                    <Typography paragraph>
                        All forms of discrimination – regardless of whether they take place on the grounds of origin, gender, sexual orientation/identity, age,
                        or because of any other reason – are strictly forbidden on campus, in the e-learning environment as well as in any other (digital)
                        platform/medium which is operated at UMFST-UMCH (or by the campus management), and – generally – in the context of the study program
                        offered at UMFST-UMCH. The student obliges himself/herself to respect and to obey this provision and all anti-discrimination regulations
                        at UMFST-UMCH.
                    </Typography>
                </li>
                <li>
                    <Typography paragraph>
                        The student commits himself/herself to apply the necessary care and due diligence when using premises, facilities, equipment,
                        installations, as well as any other kind of moveable and immovable goods which are owned by UMFST-UMCH and/or the campus operator. In
                        the event of damages (or losses) of the aforementioned objects, the student is obliged to notify immediately either UMFST-UMCH or the
                        campus management about the damage(s)/loss(es) and to cover the costs incurring as a result.
                    </Typography>
                </li>
                <li>
                    <Typography paragraph>
                        The student obliges himself/herself to peruse, acknowledge, and follow all rules and regulations concerning the use of chats, forums,
                        and all other digital communications channels which are provided by UMFST-UMCH or the campus management in the context of the
                        above-mentioned study program (as well as of any extracurricular activities).
                    </Typography>
                </li>
            </StyledOl>
            <Typography fontWeight="bold">III. Study-Relevant Behaviour</Typography>
            <StyledOl>
                <li>
                    <Typography paragraph>
                        The student commits himself/herself to perform all study-relevant assignments and to produce all relevant pieces of work (exams,
                        term/seminar papers, quizzes, homework, tests, etc.) independently, personally, and without any help by others. He/she fully respects
                        all requirements concerning good scientific practice.
                    </Typography>
                </li>
                <li>
                    <Typography paragraph>
                        The student declares to obey all rights of third parties (especially copyrights, licenses, rights to use, third parties’ rights to their
                        own image/word, etc.) when performing study-relevant assignments and/or producing relevant pieces of work (exams, term/seminar papers,
                        quizzes, homework, tests, etc.) in the context of the above-mentioned study program at UMFST-UMCH.
                    </Typography>
                </li>
                <li>
                    <Typography paragraph>
                        The student obliges himself/herself to use all kind of study materials (books, texts, scripts, exams, etc.) to which he/she gets access
                        due to his/her participation in the above-mentioned study program at UMFST-UMCH only and exclusively for private educational purposes.
                        Additionally, the student agrees not to share, to distribute, or to publish any of these materials.
                    </Typography>
                </li>
            </StyledOl>
            <Typography fontWeight="bold">IV. Recordings of Courses, Usage of the Footage, Rights to Use</Typography>
            <StyledOl>
                <li>
                    <Typography paragraph>
                        The campus management records (in form of photo, video, and sound recordings) a selection of courses and lectures which take place in
                        the context of the above-mentioned study program at UMFST-UMCH (either on campus or in digital form). These materials will be
                        (permanently) stored and made accessible to the respective course participants through the UMFST-UMCH e-learning platforms (such as
                        Canvas, Panopto, Zoom, etc.) and other study-relevant media in form of live-streams and on-demand accessible content.
                    </Typography>
                </li>
                <li>
                    <Typography paragraph>
                        The student entitles the campus management to create pictures, video footage, and voice recordings of him/her whenever the campus
                        management records courses in which the student participates, and which take place in the context of the above-mentioned study program
                        at UMFST-UMCH (or in extracurricular activities related to this program).
                    </Typography>
                </li>
                <li>
                    <Typography paragraph>
                        The campus management also creates pictures, videos, and sound footage during events and/or on other occasions which take place in
                        relation to UMFST-UMCH and its services (such as extracurricular activities, workshops, fairs, etc.).
                    </Typography>
                    <Typography paragraph>
                        The campus management is the sole owner of the copyrights and has the exclusive rights to use, to store, to share, and to process the
                        pictures, quotes, video and sound recordings which are created in accordance with paragraphs (1) and (2) of section IV of the present
                        agreement.
                    </Typography>
                    <Typography paragraph>
                        As far as a student is recognizably depicted by the aforementioned recordings, he/she declares his/her explicit consent that these
                        materials as well as all kinds of utterances, quotes, and other forms of oral expressions made during the creation of such footage
                        (including all photo, video, and sound materials created during lectures and other teaching activities) may be used by the campus
                        operator in both commercial as well as uncommercial ways. The usage of these materials may include (inter alia) their publication (or
                        the publication of parts of such materials) for advertising purposes; e.g., in print and digital media, in digital newsletters, on the
                        homepage(s) of the campus operator (especially, but not exclusively, on: https://edu.umch.de), as well as on the social media channels
                        of the campus operator. Such media channels are the following (among others):
                    </Typography>
                    <StyledUl>
                        <li>
                            <Typography>
                                Instagram account (
                                <ExternalLink href="https://www.instagram.com/edu.umch" style={{ color: theme.palette.text.primary, wordBreak: "break-all" }}>
                                    www.instagram.com/edu.umch
                                </ExternalLink>
                                ),
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                Facebook account (
                                <ExternalLink href="https://www.facebook.com/edu.umch" style={{ color: theme.palette.text.primary, wordBreak: "break-all"  }}>
                                    https://www.facebook.com/edu.umch
                                </ExternalLink>
                                ),
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                LinkedIn account (
                                <ExternalLink href="https://de.linkedin.com/company/edu-umch" style={{ color: theme.palette.text.primary, wordBreak: "break-all"  }}>
                                    https://de.linkedin.com/company/edu-umch
                                </ExternalLink>
                                ),
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                YouTube channel (
                                <ExternalLink href="https://www.youtube.com/channel/UCFm0wH69acUGyRDSXw415CQ" style={{ color: theme.palette.text.primary, wordBreak: "break-all"  }}>
                                    https://www.youtube.com/channel/UCFm0wH69acUGyRDSXw415CQ
                                </ExternalLink>
                                ), and
                            </Typography>
                        </li>
                        <li>
                            <Typography mb={2}>
                                TikTok account (
                                <ExternalLink href="https://www.tiktok.com/@umfst.umch" style={{ color: theme.palette.text.primary, wordBreak: "break-all"  }}>
                                    https://www.tiktok.com/@umfst.umch
                                </ExternalLink>
                                ).
                            </Typography>
                        </li>
                    </StyledUl>
                    <Typography paragraph>
                        The campus management also has the right to edit, re-arrange/re-structure, transform, or to change the aforementioned photo, video, and
                        audio footage in any way – as long as these changes do not result into substantial distortions.
                    </Typography>
                </li>
                <li>
                    <Typography paragraph>
                        The student does not obtain any rights of use concerning the materials created in accordance with paragraph 1 and 2 of section IV of
                        this agreement. Moreover, the student explicitly confirms that the creation of the aforementioned photo, video, and audio footage as
                        well as the transfer of such footage to the course provider (including its publication by the course providers) takes place entirely
                        voluntarily and without any form of renumeration. The student does not receive any payment/renumeration/fees – neither for the creation
                        and/or publication of the aforementioned photo, video, and audio footage, nor for the transfer of the right of use.
                    </Typography>
                </li>
            </StyledOl>
            <Typography paragraph fontWeight="bold">
                V. Additional Contractual Terms
            </Typography>
            <Typography paragraph>
                In case that any provision in this agreement shall be invalid, illegal or unenforceable, the validity, legality and enforceability of the
                remaining provisions shall not in any way be affected or impaired thereby and such provision shall be ineffective only to the extent of such
                invalidity, illegality or unenforceability.
            </Typography>
            <StoreCheckbox
                name="termsAndConditionsAccept"
                label="I agree to the aforementioned terms and conditions"
                required
                color="primary"
                sx={{ marginBottom: 2 }}
            />
            <Typography paragraph fontWeight="bold">
                VI. Data Protection
            </Typography>
            <Typography paragraph fontWeight="bold" fontStyle="italic">
                I confirm that I have read and understand the above “Declaration of Consent” amending of (i) my Study Contract with the UMFST and (ii) my
                Service Contract with CPE Europe GmbH. I understand that CPE Europe GmbH processes my personal data collected in the context of its academic
                services. This concerns inter alia name and contact information, information on academic performance including test results and participation in
                courses, records in form of photo, video, and sound recordings recorded in the context of courses and other academic activities. This processing
                of personal data is carried out in line with the academic concept of UMFST and CPE Europe GmbH and required to carry out the contractual
                obligations of CPE Europe GmbH. The data protection justification follows from Art. 6 para 1 lit. b GDPR and is not subject to my declaration of
                consent. I can find further information regarding the processing of my personal data in the below Data Protection Information.
            </Typography>
            <StoreCheckbox
                name="declarationOfConsentAccept_1"
                label="I consent to the processing of my personal data as described above"
                required
                color="primary"
                sx={{ marginBottom: 2 }}
            />
            <Typography paragraph fontWeight="bold" fontStyle="italic">
                I consent to the processing of my personal data, namely records in form of photo, video, and sound recordings collected in a non-academic
                circumstances on and off-campus and records taken in academic circumstances that are used for purposes not covered under the above stated
                agreements, namely CPE Europe GmbH and its partners’ PR activities. I understand that I am under no obligation to consent and there will be no
                consequences in case of withholding consent apart from my data not being processed for the above processing purposes. I may revoke my consent at
                any time by giving notice to CPE Europe GmbH. In such case CPE Europe GmbH will cease processing my personal data for the above mentioned
                purposes in the future. I can find further information regarding the processing of my personal data in the below Data Protection Information
            </Typography>
            <StoreCheckbox
                name="declarationOfConsentAccept_2"
                label="I consent to the processing of my personal data as described above"
                color="primary"
                sx={{ marginBottom: 2 }}
            />
            <Typography paragraph fontWeight="bold" mt={4}>
                Annex
            </Typography>
            <Typography variant="h2" sx={{ color: theme.palette.text.primary, textAlign: "center" }} mb={4} mt={4}>
                Data Protection Information
            </Typography>
            <Typography paragraph>
                Compliance with data protection regulations (in particular the European General Data Protection Regulation, hereinafter “GDPR”) and guaranteeing
                the confidentiality and privacy of personal information of our students, cooperation/business partners, and employees is of utmost importance to
                CPE Europe GmbH.
            </Typography>
            <Typography paragraph fontWeight="bold">
                Data Controller:
            </Typography>
            <Typography paragraph>
                CPE Europe GmbH, Albert-Einstein-Ring 11-15, 22761 Hamburg, Telefon: +49 (0) 40-2093485-00, E-Mail: info@edu.umch.de
            </Typography>
            <Typography paragraph fontWeight="bold">
                Data Protection Officer:
            </Typography>
            <Typography paragraph>CPE Europe GmbH, Albert-Einstein-Ring 11-15, 22761 Hamburg, Der Datenschutzbeauftragte, mail@planit.legal</Typography>
            <Typography paragraph fontWeight="bold">
                Legal Basis, Purpose, and Legitimate Interest for Processing Personal Data
            </Typography>
            <Typography paragraph>
                CPE Europe GmbH processes your personal data including name and contact information, information on academic performance including test results
                and participation in courses, records in form of photo, video, and sound recordings recorded in the context of courses and other academic
                activities. For the purpose of carrying out its contractual rights and obligations under the Service Contract concluded with you. The data
                protection justification follows from Art. 6, para. 1, lit. b GDPR.
            </Typography>
            <Typography paragraph>
                CPE Europe GmbH processes your personal data further where required under legal obligations e.g. tax, criminal or public law. The data
                protection justification follows from Art. 6, para. 1, lit. c GDPR.
            </Typography>
            <Typography paragraph>
                There are also processing operations in the legitimate interest of CPE Europe GmbH, namely the operation and optimisation of its business
                operations and in the interest of Palade University of Medicine, Pharmacy, Science, and Technology of Targu Mures in carrying out its academic
                programms. The data protection justification follows from Art. 6, para. 1, lit. f GDPR.
            </Typography>
            <Typography paragraph>
                CPE Europe GmbH also processes personal data where you have expressed consent. The affected data will in such cases be addressed in the
                respective documentation used to request your consent. The data protection justification follows from Art. 6, para. 1, lit. a and Art. 7 GDPR.
            </Typography>
            <Typography paragraph fontWeight="bold">
                Recipient / Transfer of Personal Data to Third Countries
            </Typography>
            <Typography paragraph>
                Personal data are forwarded to the CPE Europe GmbH cooperation partner George Emil Palade University of Medicine, Pharmacy, Science, and
                Technology of Targu Mures and data processors contracted by CPE Europe GmbH with the task of processing the relevant data; the contracted
                companies are (inter alia): Microsoft, Canvas, Zoom, Panopto, etc. In the process of processing your personal date, these data may also be
                transferred to recipients who are located in third countries outside the EEA, for example in the United States of America. In such cases, we
                ensure adequate protection of your personal data by ensuring that a European Commission’ decision on the adequacy at the data recipients
                location being in place or transfer mechanisms under Art. 44 et. seq GDPR being in place, in particular The European Commission’s Standard
                Contractual Clauses and where required additional safeguards.
            </Typography>
            <Typography paragraph fontWeight="bold">
                Duration for Storing Data
            </Typography>
            <Typography paragraph>
                As a matter of principle, CPE Europe GmbH stores personal data only for the duration which is required for the achievement of the above-listed
                purposes or for the duration which is required by law. By the end of this duration, CPE Europe GmbH deletes the relevant personal data in full
                conformity with the applicable data protection regulations.
            </Typography>
            <Typography paragraph fontWeight="bold">
                Your Rights
            </Typography>
            <Typography paragraph>
                As far as the processing of your personal data is based on consent, you have the right to revoke this consent at any time with effect for the
                future (Art. 7 para. 3 GDPR). The revocation of consent does not affect the lawfulness of the processing carried out on the basis of the consent
                until the revocation. It is sufficient to notify the data controller or the data protection officer of the revocation.
            </Typography>
            <Typography paragraph>Furthermore, when affected by the processing of personal data by CPE Europe GmbH, you are entitled to demand</Typography>
            <StyledUl>
                <li>
                    <Typography>a confirmation from CPE Europe GmbH that the company processes your personal data,</Typography>
                </li>
                <li>
                    <Typography>
                        information concerning the exact nature / extent of your personal data which are processed by CPE Europe GmbH (Art. 15 GDPR),
                    </Typography>
                </li>
                <li>
                    <Typography>the correction of incorrect data (Art. 16 GDPR),</Typography>
                </li>
                <li>
                    <Typography>the deletion of your personal data (Art. 17 GDPR), and</Typography>
                </li>
                <li>
                    <Typography mb={2}>the restriction / blocking of your data (Art. 18 GDPR).</Typography>
                </li>
            </StyledUl>
            <Typography paragraph>
                In addition, you are entitled – according to Art. 21 GDPR – to object the processing of your personal data by CPE Europe (in accordance with
                Art. 6, para. 1, lit e or f GDPR);. If you have provided the date personally and by yourself, you are also entitled to demand the transfer of
                such date (Art. 22 GDPR). Whether, to which extend, and under which conditions these rights can be legally invoked, is determined and further
                specified in the above-designated norms. Whether and to which extend these rights are legally effective in the particular case, depends on
                respective legal provisions.
            </Typography>
            <Typography paragraph>
                You are free to contact the responsible data protection authority (Art. 77 GDPR) for all kinds of questions and concerns in this regard.
                Moreover, you are also very welcome to discuss all issues, complaints, and questions concerning data protection at CPE Europe GmbH with the
                company’s data protection officer.
            </Typography>
            <Typography paragraph fontWeight="bold">
                Contractual Necessity or Obligation / Legal Obligation
            </Typography>
            <Typography paragraph>
                The provision of personal data is to the extent described above necessary and a substantial element of the service agreement between you and CPE
                Europe GmbH. Consequently, you are legally required to provide these data and to ensure that the data which you provide are correct. In case you
                do not or not fully provide information, CPE Europe GmbH may not be able to carry out some or any of its contractual obligations.
            </Typography>
            <Typography paragraph fontWeight="bold">
                Automatic Decision Making / Profiling
            </Typography>
            <Typography paragraph>Personal data are not used for automatic decision making / profiling in the sense of art. 22 DSGVO.</Typography>
        </StyledDeclarationOfConsent>
    );
}

const StyledDeclarationOfConsent = styled("div")(({ theme }) => ({
    fontSize: theme.typography.body1.fontSize,
}));

const StyledUl = styled("ul")({
    listStyleType: "square",
});

const StyledOl = styled("ol")({
});
