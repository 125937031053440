import { Typography } from "@mui/material";

export default function ContactInfo({ mt, mb, textAlign }) {
    return (
        <>
            <Typography mt={mt} variant="body2" textAlign={textAlign}>Should you have any questions, please do not hesitate to contact us directly.</Typography>
            <Typography variant="body2" textAlign={textAlign}>
                Phone: <a href="tel:+4940209348500">+49 (0) 40 2093485-00</a>
            </Typography>
            <Typography mb={mb} variant="body2" textAlign={textAlign}>
                E-mail: <a href="mailto:admissions@edu.umch.de">admissions@edu.umch.de</a>
            </Typography>
        </>
    );
}
