export default function useFormHelpers() {
    const required = (fields, requiredFields) =>
        Object.entries(requiredFields)
            .filter((e) => e[1] && !trim(fields)[e[0]])
            .reduce((p, e) => ({ ...p, [e[0]]: e[1] }), {});

    const emailValid = (fields, emailFields) =>
        Object.entries(emailFields)
            .filter((e) => e[1] && trim(fields)[e[0]] && !isEmail(trim(fields)[e[0]]))
            .reduce((p, e) => ({ ...p, [e[0]]: e[1] }), {});

    const dateValid = (fields, dateFields) =>
        Object.entries(dateFields)
            .filter((e) => e[1] && trim(fields)[e[0]] && !isValidDate(fields[e[0]]))
            .reduce((p, e) => ({ ...p, [e[0]]: e[1] }), {});

    const birthdateValid = (fields, dateFields) =>
        Object.entries(dateFields)
            .filter((e) => e[1] && trim(fields)[e[0]] && !isValidBirthdate(fields[e[0]]))
            .reduce((p, e) => ({ ...p, [e[0]]: e[1] }), {});

    const hasError = (errors) => Object.values(errors).reduce((p, c) => p || c, false);

    
    return { required, emailValid, dateValid, birthdateValid, hasError };
}

export const datesToISOStrings = (fields) => Object.entries(fields).reduce((p, c) => ({ ...p, [c[0]]: c[1]?.toISOString?.() || c[1] }), {});

export function isEmail(text) {
    return !!text?.trim().match(/^.+@.+\..+$/i);
}

export function trim(obj) {
    const copy = { ...obj };
    for (let key of Object.keys(copy)) {
        if (typeof copy[key] === "string") {
            copy[key] = copy[key]?.trim();
        }
    }
    return copy;
}

export function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

export function isValidBirthdate(d) {
    return isValidDate(d) && d.getFullYear() > 1900 && d.getFullYear() < new Date().getFullYear();
}
