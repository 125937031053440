import { useState } from "react";
import useApiFetch from "hooks/useApiFetch";
import { Box, Button, CircularProgress, styled, Typography } from "@mui/material";
import useOnMount from "hooks/useOnMount";

export default function Newsletter() {
    const apiFetch = useApiFetch();
    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);

    const subscribeToNewsletter =
        async (retry) => {
            setLoading(true);
            try {
                const { status } = await apiFetch("newsletter", { subscribe: true });
                if (status === 200) {
                    setDone(true);
                }
            } catch (error) {}
            if (!retry) {
                setDone(true);
            }
            setLoading(false);
        };

    useOnMount(() => {
        subscribeToNewsletter(true);
    });

    return (
        <Container>
            {!done ? (
                <>
                    {loading ? (
                        <Loading />
                    ) : (
                        <SubscribeToNewsletter subscribeToNewsletter={() => subscribeToNewsletter(false)} loading={loading} />
                    )}
                </>
            ) : (
                <Success />
            )}
        </Container>
    );
}

const Container = styled("div")({
    margin: 50,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
});

function SubscribeToNewsletter({ subscribeToNewsletter }) {
    return (
        <div>
            <Button color="primary" size="large" sx={{ textDecoration: "underline" }} onClick={subscribeToNewsletter}>
                Click here to subscribe to the UMFST-UMCH newsletter
            </Button>
        </div>
    );
}

function Loading() {
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 100 }}>
            <CircularProgress size={40} />
        </Box>
    );
}

function Success() {
    return (
        <div>
            <Typography paragraph>You have successfully subscribed to the UMFST-UMCH newsletter.</Typography>
        </div>
    );
}
