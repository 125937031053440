import { TextField } from "@mui/material";
import useStore from "hooks/useStore";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";

export default function StoreTextField({ type, name, required, label, ...props }) {
    const setPartialFormState = useStore((state) => state.setPartialFormState);
    const errors = useStore((state) => state.formErrors);
    const formState = useStore((state) => state.formState);
    const requiredFormFields = useStore((state) => state.requiredFormFields);
    const setRequiredFormField = useStore((state) => state.setRequiredFormField);
    const setEmailFormField = useStore((state) => state.setEmailFormField);
    const student = useStore((state) => state.student);

    useEffect(() => {
        setRequiredFormField(name, required);
    }, [required, name, setRequiredFormField]);

    useEffect(() => {
        setEmailFormField(name, type === "email");
    }, [type, name, setEmailFormField]);

    const onChange = (event) => {
        setPartialFormState({ [name]: event.target.value?.trimStart() });
    };

    return student ? (
        <TextField
            {...props}
            name={name}
            onChange={onChange}
            value={formState[name] || ""}
            error={!!errors[name]}
            label={`${label}${requiredFormFields[name] ? " *" : ""}`}
            helperText={errors[name] ? "Please enter a correct value" : ""}
            required={false}
            InputLabelProps={{ shrink: true }}
            type={type}
        />
    ) : (
        <Skeleton height={59} width={props?.width}/>
    );
}

StoreTextField.defaultProps = {
    required: false,
};
