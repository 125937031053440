import { styled, Button, Grid, Typography, Stack } from "@mui/material";
import useStore from "hooks/useStore";
import useValidateForm from "hooks/useValidateForm";
import { useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import useApiFetch from "hooks/useApiFetch";
import { useRef } from "react";
import Skeleton from "react-loading-skeleton";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export default function Section({ title, number, children, last, errorMessage }) {
    return (
        <NextSection section={number} last={last} errorMessage={errorMessage}>
            <Container>
                <Flex>
                    <Number>{number}</Number>
                    <Typography variant="h2">{title}</Typography>
                </Flex>
                {children}
            </Container>
        </NextSection>
    );
}

function NextSection({ section, children, last, errorMessage = "Please fill all required fields." }) {
    const currentSection = useStore((state) => state.currentSection);
    const formState = useStore((state) => state.formState);
    const setCurrentSection = useStore((state) => state.setCurrentSection);
    const student = useStore((state) => state.student);
    const validate = useValidateForm();
    const [hasError, setHasError] = useState(false);
    const apiFetch = useApiFetch();
    const ref = useRef(null);

    const scroll = () => {
        ref.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    };

    const next = () => {
        setHasError(false);
        const { isValid, errors } = validate();
        if (isValid) {
            setCurrentSection(section + 1);
            setTimeout(() => scroll(), 0);
        } else {
            setHasError(true);
            apiFetch("error", { subject: "[Warning] Frontend Validation Failed", method: "next", data: { section, formState, formErrors: errors } }).catch(
                () => {}
            );
        }
    };

    return (
        <>
            {currentSection >= section && children}
            {!last && currentSection === section && (
                <div>
                    {hasError && (
                        <Stack direction="horizontal" mb={2}>
                            <ErrorOutlineIcon color="error" fontSize="medium"/>
                            <Typography color="error" fontWeight="bold" pl={1} lineHeight={1.6}>
                                {errorMessage}
                            </Typography>
                        </Stack>
                    )}
                    <Grid container>
                        <Grid item xs={12} md={5}>
                            {student ? (
                                <Button
                                    fullWidth
                                    onClick={next}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    sx={{ marginBottom: 10 }}
                                    startIcon={<ArrowForwardIcon />}
                                >
                                    Continue with step {section + 1}
                                </Button>
                            ) : (
                                <Skeleton height={46} style={{ marginBottom: 80 }} />
                            )}
                        </Grid>
                    </Grid>
                </div>
            )}
            <div ref={ref} />
        </>
    );
}

const Container = styled("div")({
    margin: "48px 0 60px 0",
});

const Flex = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(1240)]: {
        marginLeft: "-120px",
        flexDirection: "row",
        alignItems: "center",
    },
}));

const Number = styled("div")(({ theme }) => ({
    padding: "0 5px",
    marginBottom: 10,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontSize: 22,
    fontWeight: "bold",
    [theme.breakpoints.up(1240)]: {
        width: 100,
        textAlign: "end",
        marginRight: 10,
    },
}));
