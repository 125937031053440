const { styled } = require("@mui/material");

const Container = styled("div")(({ theme }) => ({
    padding: "0 20px",
    margin: "0 auto",
    [theme.breakpoints.up(1240)]: {
        padding: "0 120px",
        maxWidth: 1000,
    },
}));

export default Container;
