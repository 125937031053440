import { Alert } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import useErrorToString from "hooks/useErrorToString";
import useStore from "hooks/useStore";

export default function ErrorSnackbar() {
    const setError = useStore((state) => state.setError);
    const error = useStore((state) => state.error);
    const errorToString = useErrorToString();

    const handleClose = () => {
        setError("");
    };

    return (
        <Snackbar open={!!error} autoHideDuration={20000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
                {errorToString(error)}
            </Alert>
        </Snackbar>
    );
}
