import { Grid, Typography, styled } from "@mui/material";
import Section from "components/Section";
import Subsection from "components/Subsection";
import StoreUploadButton from "components/StoreUploadButton";

export default function Picture() {

    return (
        <Section title="Passport-sized Picture for your Student ID Card" number={2} errorMessage="Please upload a picture.">
            <Subsection>
                <Typography>
                    Please upload a passport-sized photo of yourself. It is important that your face is clearly visible and that you are recognizable in the
                    picture. We will use it for your Student ID card. During the Welcome Days all new students will receive such an individualized card, which
                    will serve both as a key card for access to the campus and as a student ID.
                </Typography>
            </Subsection>
            <Subsection>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography fontWeight="bold">Example of a successful portrait:</Typography>
                        <PortraitImage src="/images/passwort-picture-example.png" alt="" width="403" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FlexCol>
                            <Typography>No suitable portrait image available? We recommend the following app for the ideal passport photo:</Typography>
                            <AppImage src="/images/passport-app.png" alt="" width="678" />
                            <FlexRow>
                            <a href="https://play.google.com/store/apps/details?id=id.passport.photo.lite&hl=en_US&gl=US" target="_blank" rel="noreferrer">
                                <StoreBadge src="/images/google-play-badge.png" alt="" width={200} />
                            </a>
                            <a href="https://apps.apple.com/us/app/passport-photo-id-photo-app/id1294190634" target="_blank" rel="noreferrer">
                                <StoreBadge src="/images/apple-store-badge.png" width={200} />
                            </a>
                            </FlexRow>
                        </FlexCol>
                    </Grid>
                </Grid>
                <Typography fontWeight="bold" sx={{ marginTop: "10px", marginBottom: "10px" }}>Please note: A scan of your ID card, holiday or party photo is not acceptable.</Typography>
            </Subsection>
            <Subsection>
                <StoreUploadButton required name="studentIdCardPicture" label="Upload Passport-sized Picture" />
            </Subsection>
        </Section>
    );
}

const FlexCol = styled("div")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%"
});

const FlexRow = styled("div")({
    display: "flex",
    gap: "20px"
});

const PortraitImage = styled("img")({
    objectFit: "contain",
    marginTop: "5px"
});

const AppImage = styled("img")({
    objectFit: "contain",
    maxWidth: 400,
    marginBottom: "20px"
});

const StoreBadge = styled("img")({
    objectFit: "contain",
    width: 200,
});