import { Typography } from "@mui/material";
import Subsection from "components/Subsection";

export default function Greeting() {
    return (
        <Subsection>
            <Typography variant="h1" mb={2}>
                Dear Upcoming Student
            </Typography>
            <Typography color="default" paragraph>
                Welcome to UMFST-UMCH's Digital Document Station for all upcoming students.
            </Typography>
            <Typography color="default" paragraph>
                By filling out the required fields, you will take a big step towards your enrollment at UMFST-UMCH. Please complete all fields by September 10.
                To do so, please follow the 5 steps below.
            </Typography>
            <Typography color="default" paragraph>
                Important Note: Please take your time to carefully read all of the documents and watch the security video in its entirety.
            </Typography>
            <Typography color="default">We thank you for your help and look forward to welcoming you to the campus soon.</Typography>
        </Subsection>
    );
}
