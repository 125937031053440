import { BlobServiceClient } from "@azure/storage-blob";
import { AbortController } from "@azure/abort-controller";
import { v4 as uuidv4 } from 'uuid';

// https://docs.microsoft.com/en-us/azure/storage/blobs/quickstart-blobs-javascript-browser

const CONTAINER_NAME = "digital-document-station";

const blobServiceClient = new BlobServiceClient(process.env.REACT_APP_BLOB_SAS_URL);
const containerClient = blobServiceClient.getContainerClient(CONTAINER_NAME);

const controllers = {};

export default function useUploadFile(id = uuidv4()) {
    const uploadFile = (file, fileName) => {
        controllers[id] = new AbortController();
        const blockBlobClient = containerClient.getBlockBlobClient(fileName);
        return blockBlobClient.uploadData(file, { abortSignal: controllers[id].signal });
    };

    const cancelUploadFile = () => { 
        controllers[id]?.abort();
        controllers[id] = null;
    };

    return { uploadFile, cancelUploadFile };
}
