import { Checkbox, FormControlLabel, useTheme } from "@mui/material";
import useStore from "hooks/useStore";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";

export default function StoreCheckbox({ onChange, name, required, label, sx, color, ...props }) {
    const theme = useTheme();
    const setPartialFormState = useStore((state) => state.setPartialFormState);
    const errors = useStore((state) => state.formErrors);
    const formState = useStore((state) => state.formState);
    const requiredFormFields = useStore((state) => state.requiredFormFields);
    const setRequiredFormField = useStore((state) => state.setRequiredFormField);
    const student = useStore((state) => state.student);

    useEffect(() => {
        setRequiredFormField(name, required);
    }, [required, name, setRequiredFormField]);

    const _onChange = (event) => {
        if (!!onChange) {
            onChange(event);
        } else {
            setPartialFormState({ [name]: event.target.checked });
        }
    };

    return student ? (
        <FormControlLabel
            control={
                <Checkbox
                    {...props}
                    name={name}
                    onChange={_onChange}
                    checked={!!formState[name]}
                    sx={{ color: errors[name] ? theme.palette.error.main : color ? theme.palette[color].main : theme.palette.primary.main }}
                    required={false}
                />
            }
            label={label ? `${label}${requiredFormFields[name] ? " *" : ""}` : ""}
            sx={{ ...sx, color: errors[name] ? theme.palette.error.main : color ? theme.palette[color].main : theme.palette.text.primary }}
        />
    ) : (
        <Skeleton height={45} width={props?.width} />
    );
}

StoreCheckbox.defaultProps = {
    required: false,
};
