import { Alert } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import useErrorToString from "hooks/useErrorToString";
import useStore from "hooks/useStore";

export default function InfoSnackbar() {
    const setInfo = useStore((state) => state.setInfo);
    const info = useStore((state) => state.info);
    const errorToString = useErrorToString();

    const handleClose = () => {
        setInfo("");
    };

    return (
        <Snackbar open={!!info} autoHideDuration={10000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="warning">
                {errorToString(info)}
            </Alert>
        </Snackbar>
    );
}
