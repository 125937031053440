import { useEffect, useState } from "react";
import ContactInformation from "sections/ContactInformation";
import Done from "sections/Done";
import Picture from "sections/Picture";
import SafetyInstruction from "sections/SafetyInstruction";
import Documents from "sections/Documents";
import Greeting from "sections/Greeting";
import Container from "components/Container";
import useStore from "hooks/useStore";
import useApiFetch from "hooks/useApiFetch";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SuccessIcon } from "images/ok-tick.svg";
import ContactInfo from "components/ContactInfo";
import { LoadingButton } from "@mui/lab";
import { datesToISOStrings } from "hooks/useFormHelpers";
import useLock from "hooks/useLock";
import useValidateForm from "hooks/useValidateForm";
import Legend from "sections/Legend";

export default function Form() {
    const apiFetch = useApiFetch();
    const student = useStore((state) => state.student);
    const setStudent = useStore((state) => state.setStudent);
    const setError = useStore((state) => state.setError);
    const setPartialFormState = useStore((state) => state.setPartialFormState);
    const formState = useStore((state) => state.formState);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const validate = useValidateForm();

    useEffect(() => {
        const loadStudent = async () => {
            if (!student) {
                try {
                    const { status, statusText, data } = await apiFetch("student");
                    if (status === 200) {
                        setStudent(data.account);
                        setPartialFormState({
                            firstName: data.account.firstName,
                            lastName: data.account.lastName,
                            email: data.account.email,
                            isSubmitted: data.account.alreadyUploaded,
                        });
                    } else {
                        setError(statusText);
                        navigate("/login");
                    }
                } catch (error) {
                    setError("An error occurred. Please try again.");
                    navigate("/login");
                }
            }
        };
        loadStudent();
    }, [apiFetch, navigate, setError, setPartialFormState, setStudent, student]);

    const onSubmit = useLock(async (event) => {
        event.preventDefault();
        setLoading(true);
        const { isValid, errors } = validate();
        if (isValid) {
            try {
                const data = { ...datesToISOStrings(formState), timezone: Intl.DateTimeFormat().resolvedOptions().timeZone };
                const { status, statusText } = await apiFetch("upload", data);
                if (status === 200) {
                    setPartialFormState({ isSubmitted: true });
                } else {
                    setError(statusText);
                }
            } catch (error) {
                apiFetch("error", { message: error.message, method: "onSubmit", data: formState }).catch(() => {});
                setError("An error occurred. Please make sure you filled all required fields and try again.");
            }
        } else {
            apiFetch("error", { subject: "[Warning] Frontend Validation Failed", method: "validate", data: { formState, formErrors: errors } }).catch(() => {});
            setError("Please fill all required fields");
        }
        setLoading(false);
    });

    return (
        <>
            {!student && <LinearProgress style={{ position: "fixed", top: 0, left: 0, right: 0 }} />}
            <Container>
                {formState.isSubmitted ? (
                    <Success />
                ) : (
                    <form onSubmit={onSubmit}>
                        <Greeting />
                        <ContactInformation />
                        <Picture />
                        <SafetyInstruction />
                        <Documents />
                        <Done loading={loading} />
                        <Legend />
                    </form>
                )}
            </Container>
        </>
    );
}

function Success() {
    const apiFetch = useApiFetch();
    const setError = useStore((state) => state.setError);
    const [loading, setLoading] = useState(false);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 4 }}>
            <SuccessIcon height={80} />
            <Typography variant="h1" paragraph mt={0}>
                Thank you!
            </Typography>
            <Typography color="primary" paragraph textAlign="center" mb={6} sx={{ maxWidth: 500 }}>
                We have received your information and look forward to welcoming you to the campus soon.
            </Typography>
            <ContactInfo textAlign="center" />
            {window.location.hostname === "localhost" && (
                <LoadingButton
                    loading={loading}
                    sx={{ marginTop: 2, opacity: 0.2, transform: "scale(0.75)" }}
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={() => {
                        setLoading(true);
                        apiFetch("reset")
                            .then(({ status, statusText }) => {
                                setLoading(false);
                                if (status === 200) window.location.reload();
                                else setError(statusText);
                            })
                            .catch((e) => {
                                setError("Failed");
                            });
                    }}
                >
                    __Reset__
                </LoadingButton>
            )}
        </Box>
    );
}
