import { Button, Grid, Typography } from "@mui/material";
import StoreTextField from "components/StoreTextField";
import StoreToggleButton from "components/StoreToggleButton";
import ToggleButtonGrid from "components/ToggleButtonGrid";
import StoreCheckbox from "components/StoreCheckbox";
import Section from "components/Section";
import Subsection from "components/Subsection";
import useStore from "hooks/useStore";
import DownloadIcon from "@mui/icons-material/Download";
import ExternalLink from "components/ExternalLink";
import StoreDateField from "components/StoreDateField";
import StoreUploadButton from "components/StoreUploadButton";
import useUploadFile from "hooks/useUploadFile";
import { useEffect, useState } from "react";
import { formatInTimeZone } from "date-fns-tz";

export default function ContactInformation() {
    return (
        <Section title="Contact Information and Language Skills" number={1} errorMessage="Please fill all required fields and try again.">
            <Header />
            <PersonalContact />
            <IsAdult />
            <MotherContact />
            <FatherContact />
            <LanguageSkills />
        </Section>
    );
}

function Header() {
    return (
        <Subsection>
            <Typography>
                Now that you have officially started your academic year at UMFST-UMCH, we would like to keep you updated with all the relevant information.
                Therefore, we require your current contact information.
            </Typography>
        </Subsection>
    );
}

function IsAdult() {
    const formState = useStore((state) => state.formState);

    return (
        <Subsection>
            <Typography mb={1} fontWeight="bold">
                Are you 18 years or older? *
            </Typography>
            <Grid container>
                <Grid item xs={12} sm={5} md={4} lg={3}>
                    <ToggleButtonGrid columns={2}>
                        <StoreToggleButton name="isAdult" value="adult" required>
                            Yes
                        </StoreToggleButton>
                        <StoreToggleButton name="isAdult" value="minor" required>
                            No
                        </StoreToggleButton>
                    </ToggleButtonGrid>
                </Grid>
            </Grid>
            {formState.isAdult === "minor" && (
                <>
                    <Typography mt={2} mb={2}>
                        If you are under 18, please download and fill out the parental consent form and send it back to us at{" "}
                        <a href="mailto:admisions@edu.umch.de">admisions@edu.umch.de</a> until September 10, {formatInTimeZone(new Date(), "Europe/Berlin", "yyyy")}.
                    </Typography>
                    <Button component="label" variant="contained" sx={{ marginBottom: 2 }}>
                        <DownloadIcon />
                        <ExternalLink href="/word/UMFST_UMCH_Parental_consent_form.docx" style={{ textDecoration: "none", color: "white" }}>
                            &nbsp;Parental consent form
                        </ExternalLink>
                    </Button>
                </>
            )}
        </Subsection>
    );
}

function PersonalContact() {
    return (
        <Subsection>
            <Typography mb={1} fontWeight="bold">
                Your personal contact details (Hamburg address)
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <StoreTextField fullWidth name="firstName" label="First name **" disabled />
                </Grid>
                <Grid item xs={12} md={6}>
                    <StoreTextField fullWidth name="lastName" label="Last name **" disabled />
                </Grid>
                <Grid item xs={12}>
                    <StoreDateField required fullWidth name="dateOfBirth" label="Date of birth" />
                </Grid>
                <Grid item xs={12}>
                    <StoreTextField required fullWidth name="street" label="Street and street number" />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <StoreTextField required fullWidth name="zipCode" label="Zip code" type="number" />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <StoreTextField required fullWidth name="city" label="City" />
                </Grid>
                <Grid item xs={12}>
                    <StoreTextField required fullWidth name="phone" label="Phone number" type="phone" />
                </Grid>
                <Grid item xs={12}>
                    <StoreTextField fullWidth name="email" label="Email **" type="email" disabled />
                </Grid>
                <Grid item xs={12}>
                    <StoreTextField fullWidth name="alternativeEmail" label="Alternative email" type="email" />
                </Grid>
            </Grid>
            <Typography sx={{ mt:2, fontSize: 13, opacity: 0.75 }}>
                * All fields marked with an asterisk* are required.
            </Typography>
            <Typography sx={{ fontSize: 13, opacity: 0.75 }}>
                ** Name and email cannot be changed here. If you want to change them please contact <a href="mailto:admissions@edu.umch.de">admissions@edu.umch.de</a>.
            </Typography>
        </Subsection>
    );
}

function MotherContact() {
    const formState = useStore((state) => state.formState);

    return (
        <Subsection>
            <Typography mb={1} fontWeight="bold">
                Mother or legal guardian(s) / legal representative(s) 1
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <StoreTextField
                        fullWidth
                        name="firstNameMother"
                        label="First name"
                        maxLength={40}
                        required={formState.isAdult === "minor" || formState.emergencyContactMother}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <StoreTextField
                        fullWidth
                        name="lastNameMother"
                        label="Last name"
                        maxLength={80}
                        required={formState.isAdult === "minor" || formState.emergencyContactMother}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StoreTextField
                        fullWidth
                        name="phoneMother"
                        label="Phone number"
                        type="phone"
                        required={formState.isAdult === "minor" || formState.emergencyContactMother}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StoreTextField
                        fullWidth
                        name="emailMother"
                        label="Email"
                        type="email"
                        required={formState.isAdult === "minor" || formState.newsletterMother}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StoreCheckbox name="emergencyContactMother" label="Emergency contact" required={formState.isAdult === "minor"} />
                    <StoreCheckbox name="newsletterMother" label="Receive relevant information and news about the UMFST-UMCH Human Medicine degree program (recommended)" />
                </Grid>
            </Grid>
        </Subsection>
    );
}

function FatherContact() {
    const formState = useStore((state) => state.formState);

    return (
        <Subsection>
            <Typography mb={1} fontWeight="bold">
                Father or legal guardian(s) / legal representative(s) 2
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <StoreTextField fullWidth name="firstNameFather" maxLength={40} label="First name" required={formState.emergencyContactFather} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <StoreTextField fullWidth name="lastNameFather" maxLength={80} label="Last name" required={formState.emergencyContactFather} />
                </Grid>
                <Grid item xs={12}>
                    <StoreTextField fullWidth name="phoneFather" label="Phone number" type="phone" required={formState.emergencyContactFather} />
                </Grid>
                <Grid item xs={12}>
                    <StoreTextField fullWidth name="emailFather" label="Email" type="email" required={formState.newsletterFather} />
                </Grid>
                <Grid item xs={12}>
                    <StoreCheckbox name="emergencyContactFather" label="Emergency contact" />
                    <StoreCheckbox name="newsletterFather" label="Receive relevant information and news about the UMFST-UMCH Human Medicine degree program (recommended)" />
                </Grid>
            </Grid>
        </Subsection>
    );
}

function LanguageSkills() {
    const formState = useStore((state) => state.formState);
    const { cancelUploadFile } = useUploadFile("languageSkillAssessment");
    const [required, setRequired] = useState(false);

    const onClick = () => {
        cancelUploadFile();
    };

    useEffect(() => {
        setRequired(formState.languageSkill && formState.languageSkill !== "germanNativeSpeaker");
    }, [formState]);

    return (
        <Subsection>
            <Typography variant="h3">Registration for the German course at UMFST-UMCH (for international students)</Typography>
            <Grid container alignItems="flex-start">
                <Grid item xs={12} md={5} container rowSpacing={2} columnSpacing={4}>
                    <Grid item xs={12}>
                        <Typography>Your German language skills:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ToggleButtonGrid columns={3}>
                            <StoreToggleButton name="languageSkill" value="A1">A1</StoreToggleButton>
                            <StoreToggleButton name="languageSkill" value="B1">B1</StoreToggleButton>
                            <StoreToggleButton name="languageSkill" value="C1">C1</StoreToggleButton>
                            <StoreToggleButton name="languageSkill" value="A2">A2</StoreToggleButton>
                            <StoreToggleButton name="languageSkill" value="B2">B2</StoreToggleButton>
                            <StoreToggleButton name="languageSkill" value="C2">C2</StoreToggleButton>
                            <StoreToggleButton name="languageSkill" value="germanNativeSpeaker" span={3}>German native speaker</StoreToggleButton>
                            <StoreToggleButton name="languageSkill" value="" span={3}>No German language skills</StoreToggleButton>
                        </ToggleButtonGrid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={2}>
                    &nbsp;
                </Grid>
                <Grid item xs={12} md={5} container rowSpacing={2} columnSpacing={4}>
                    <Grid item xs={12}>
                        <Typography>&nbsp;</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ToggleButtonGrid>
                            <StoreUploadButton
                                required={required}
                                disabled={!required}
                                name="languageSkillAssessment"
                                label="Upload Language certificate"
                                fullWidth
                            />
                            <StoreToggleButton
                                name="languageSkillAssessment"
                                value="selfAssessment"
                                required={required}
                                disabled={!required}
                                onClick={onClick}
                                label="Self-assessment"
                            />
                        </ToggleButtonGrid>
                    </Grid>
                </Grid>
            </Grid>
        </Subsection>
    );
}
