import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Form from "pages/Form";
import Login from "pages/Login";
import Layout from "components/Layout";
import { ThemeProvider } from "@mui/material";
import theme from "Theme";
import Newsletter from "pages/Newsletter";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import NotFound from "pages/NotFound";

function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route path="form" element={<Form />} />
                            <Route path="login" element={<Login />} />
                            <Route path="newsletter" element={<Newsletter />} />
                            <Route index element={<Navigate to="login" />} />
                            <Route path="*" element={<NotFound />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </LocalizationProvider>
    );
}

export default App;
