import { createTheme } from "@mui/material";

const theme = createTheme({
    typography: {
        fontSize: 16
    }
});

theme.typography.h1 = {
    fontSize: 30,
    color: theme.palette.primary.main,
    paddingTop: 10
};

theme.typography.h2 = {
    fontSize: 20,
    color: theme.palette.primary.main
};

theme.typography.h3 = {
    fontSize: 20,
    color: theme.palette.primary.main,
    paddingBottom: 10
};

theme.typography.subtitle1 = {
    fontSize: 12,
    fontWeight: "normal"
};

export default theme;