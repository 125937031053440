import { styled } from "@mui/material";

export default function ToggleButtonGrid({ columns, columnSize, sx, ...props }) {
    return <StyledGrid {...props} sx={{ ...sx, gridTemplateColumns: `repeat(${columns}, ${columnSize})` }} />;
}

ToggleButtonGrid.defaultProps = {
    columns: 1,
    columnSize: "1fr",
    sx: {},
};

const StyledGrid = styled("div")(({ theme }) => ({
    display: "grid",
    gridRowGap: theme.spacing(2),
    gridColumnGap: theme.spacing(4),
}));
