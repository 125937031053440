// https://spin.atomicobject.com/2018/09/10/javascript-concurrency/
const useLock = (proc, timeout = 30000) => {
    let inFlight = null;
    let time = null;
    return (...args) => {
        if (!inFlight) {
            inFlight = (async () => {
                try {
                    if (typeof timeout === "number") {
                        time = setTimeout(() => {
                            inFlight = false;
                        }, timeout);
                    }
                    return await proc(...args);
                } finally {
                    inFlight = false;
                    if (time) clearTimeout(time);
                    time = null;
                }
            })();
        }
        return inFlight;
    };
};

export default useLock;
