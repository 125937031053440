import { PlayCircleFilledOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import ContactInfo from "components/ContactInfo";
import Section from "components/Section";
import Subsection from "components/Subsection";

export default function Done({ loading }) {
    return (
        <Section title="Done!" number={5} last={true}>
            <Subsection>
                <Typography variant="h3" mb={1}>
                    I confirm that all the information I have provided is correct and complete!
                </Typography>
                <LoadingButton size="large" type="submit" variant="contained" color="primary" fullWidth loading={loading} sx={{ marginBottom: 6 }}>
                    <Typography variant="button" lineHeight="normal">
                        Send data to CPE Europe Admission Board&nbsp;
                    </Typography>
                    <PlayCircleFilledOutlined />
                </LoadingButton>
            </Subsection>
            <Subsection>
                <ContactInfo />
            </Subsection>
        </Section>
    );
}
