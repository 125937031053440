import { useTheme, Typography } from "@mui/material";
import useStore from "hooks/useStore";
import { useEffect, useRef, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import useUploadFile from "hooks/useUploadFile";
import { LoadingButton } from "@mui/lab";
import { v4 as uuidv4 } from "uuid";
import Skeleton from "react-loading-skeleton";

export const MAX_UPLOAD_SIZE = 4 * 1024 * 1024;

export default function StoreUploadButton({ disabled, label, name, required, sx, style, span, size = "large", ...props }) {
    const setPartialFormState = useStore((state) => state.setPartialFormState);
    const formState = useStore((state) => state.formState);
    const errors = useStore((state) => state.formErrors);
    const setError = useStore((state) => state.setError);
    const setRequiredFormField = useStore((state) => state.setRequiredFormField);
    const theme = useTheme();
    const ref = useRef(null);
    const { uploadFile, cancelUploadFile } = useUploadFile(name);
    const [loading, setLoading] = useState(false);
    const student = useStore((state) => state.student);

    useEffect(() => {
        setRequiredFormField(name, required);
    }, [required, name, setRequiredFormField]);

    useEffect(() => {
        if (disabled) {
            cancelUploadFile();
            if (ref.current) ref.current.value = "";
            setPartialFormState({ [name]: null });
            setLoading(false);
        }
    }, [cancelUploadFile, disabled, name, setPartialFormState]);

    const onClick = () => {
        if (ref.current) ref.current.value = "";
        setPartialFormState({ [name]: null });
    };

    const onChange = async (event) => {
        if (!loading && event.target.files?.length > 0) {
            const file = event.target.files[0];
            if (file.size < MAX_UPLOAD_SIZE) {
                setLoading(true);
                try {
                    const storageFilename = `${uuidv4()}_${file.name}`;
                    await uploadFile(file, storageFilename);
                    setPartialFormState({ [name]: { name: file.name, mimetype: file.mimetype, storageFilename } });
                } catch (e) {
                    if (e.name !== "AbortError") {
                        setError(e);
                    }
                }
                setLoading(false);
            } else {
                setError("File must be smaller than 4mb");
            }
        }
    };

    return student ? (
        <div>
            <LoadingButton
                {...props}
                onClick={onClick}
                loading={loading}
                disabled={disabled}
                size={size}
                style={{
                    ...style,
                    borderColor: errors[name] ? theme.palette.error.main : theme.palette.primary.main,
                }}
                sx={{
                    ...sx,
                    color: errors[name] ? theme.palette.error.main : theme.palette.primary.main,
                }}
                component="label"
                variant="outlined"
            >
                <FileUploadIcon color={disabled || loading ? "disabled" : errors[name] ? "error" : "primary"} />
                <Typography variant="button" textAlign="center" ml={1}>
                    {label}&nbsp;{required ? "*" : ""}
                </Typography>
                <input hidden accept="image/*" type="file" name={name} onChange={onChange} ref={ref} disabled={disabled} />
            </LoadingButton>
            {!!formState[name]?.name && (
                <Typography color="secondary" mt={2}>
                    {ellipsis(formState[name].name)}
                </Typography>
            )}
        </div>
    ) : (
        <Skeleton height={46} width={props?.width} />
    );
}

StoreUploadButton.defaultProps = {
    required: false,
};

function ellipsis(text) {
    return !text || text.length < 40 ? text : `${text.slice(0, 18)}...${text.slice(-18)}`;
}
