import { TextField } from "@mui/material";
import useStore from "hooks/useStore";
import { useEffect } from "react";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { isValidBirthdate } from "hooks/useFormHelpers";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";

export default function StoreDateField({ type, name, required, label, fullWidth, ...props }) {
    const setPartialFormState = useStore((state) => state.setPartialFormState);
    const errors = useStore((state) => state.formErrors);
    const formState = useStore((state) => state.formState);
    const requiredFormFields = useStore((state) => state.requiredFormFields);
    const setRequiredFormField = useStore((state) => state.setRequiredFormField);
    const setDateFormField = useStore((state) => state.setDateFormField);
    const [valid, setValid] = useState(true);
    const student = useStore((state) => state.student);

    useEffect(() => {
        setRequiredFormField(name, required);
    }, [required, name, setRequiredFormField]);

    useEffect(() => {
        setDateFormField(name, true);
    }, [name, setDateFormField]);

    const onChange = (date) => {
        setPartialFormState({ [name]: date });
        if (isValidBirthdate(date)) {
            setValid(true);
        }
    };

    const onBlur = () => {
        setValid(isValidBirthdate(formState[name]));
    };

    return student ? (
        <MobileDatePicker
            {...props}
            name={name}
            onChange={onChange}
            value={formState[name] || null}
            label={`${label}${requiredFormFields[name] ? " *" : ""}`}
            renderInput={(props) => (
                <TextField
                    {...props}
                    fullWidth={fullWidth}
                    InputLabelProps={{ shrink: true }}
                    error={!!errors[name] || !valid}
                    helperText={
                        !valid
                            ? `Date must be between 1900 and ${new Date().getFullYear() - 1}`
                            : errors[name]
                            ? "Please enter a valid date (mm/dd/yyyy)"
                            : "mm/dd/yyyy"
                    }
                    required={false}
                    inputProps={{
                        ...props.inputProps,
                        placeholder: "",
                    }}
                    onBlur={onBlur}
                />
            )}
        />
    ) : (
        <Skeleton height={59} width={props?.width} />
    );
}

StoreDateField.defaultProps = {
    required: false,
    fullWidth: false,
};
