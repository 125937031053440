import useQuery from "./useQuery";

export default function useApiFetch() {
    const query = useQuery();
    
    return async (path, params) => {
        let body = JSON.stringify({ ...params });
        const headers = { "Content-Type": "application/json" };

        const jwt = query.get("jwt");
        if (jwt) {
            headers.Authorization = `Bearer ${jwt}`;
        }

        const res = await fetch(`/api/${path}`, {
            method: "POST",
            headers,
            body,
        });

        let data = await res.json().catch(e => null);

        let statusText;
        if (data?.message) {
            statusText = data?.message;
        } else if (res.statusText) {
            statusText = res.statusText;
        } else {
            statusText = `Server Response: ${res.status}`;
        }

        return {
            status: res.status,
            statusText,
            data,
        };
    };
}
