import { Typography } from "@mui/material";
import Subsection from "components/Subsection";

export default function Legend() {
    return (
        <Subsection>
            <Typography mt={0} sx={{ fontSize: 13, opacity: 0.75 }}>
                * All fields marked with an asterisk* are required.
            </Typography>
        </Subsection>
    );
}